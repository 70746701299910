import styled from 'styled-components';

const ReformItemStyle = styled.div`
	--imgSize: 41.5%;
	--tabMarginT: var(--sp5x);
	--tabMarginB: var(--sp5x);
	--statusPadTB: var(--sp1-5x);
	--statusPadLR: var(--sp2-5x);
	--imgBorderRadius: var(--sp2-5x);
	--imgMarginB: var(--sp4x);
	--aboutContentMarginB: var(--sp5x);
	--editorFontSize: var(--p3);

	overflow: hidden;

	.text-wrapper {
		font-size: var(--p1);
	}

	.wrapper-status {
		padding: var(--statusPadTB) var(--statusPadLR);
		border: 2px solid var(--blue900);
		border-radius: var(--sp3x);
	}

	.tabs {
		justify-content: space-between;
		margin: var(--tabMarginT) 0 var(--tabMarginB);
		position: relative;
	}

	.empty-flex {
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: calc(-1 * var(--tabMarginB) / 2);
		background-color: var(--backgroundColor);

		.tumbler-item {
			background-color: var(--lightBlue);
			position: relative;
			left: 0;
			height: var(--sp0-5x);
			border-radius: var(--sp0-5x);
			top: 50%;
			transform: translateY(-50%);
			width: 10px;
		}
	}

	.wrapper-navigation-inner-collapse {
		width: fit-content;
		min-width: 100%;

		gap: var(--sp2-5x);

		.info-item-tabs-wrapper {
			white-space: nowrap;
			font-family: var(--montserrat_arm);
			font-weight: 400;
		}

		.active {
			color: var(--lightBlue);
		}
	}

	.info-item-tabs-wrapper {
		cursor: pointer;
		transition: all var(--trTime) ease-out;
	}

	.img-wrapper {
		--proportion: var(--imgSize);
		margin-bottom: var(--imgMarginB);

		img {
			border-radius: var(--imgBorderRadius);
		}
	}

	.tab-content-bottom,
	.checkout-collapse {
		display: flex;
		align-items: center;
		line-height: 1;
	}

	.checkout-collapse {
		width: fit-content;
	}

	.arrow-icon {
		font-size: var(--sizeArrow);
		transition: all var(--trTime) ease-out;
		transform: translateX(0%) rotate(-90deg);
	}

	@keyframes arrowAnim {
		50% {
			transform: translateX(20%) rotate(-90deg);
		}
		100% {
			transform: translateX(0%) rotate(-90deg);
		}
	}

	.tab-content-bottom {
		justify-content: space-between;
		.youtube-icon {
			margin-right: var(--sp1x);
		}
	}

	.checkout-link,
	.arrow-icon,
	.video-link,
	.youtube-icon {
		color: var(--lightBlue);
	}

	.arrow-icon {
		margin-left: var(--sp1x);
		font-size: var(--p1);
		transform: rotate(270deg);
		display: inline-flex;
	}

	.about-content {
		margin-bottom: var(--aboutContentMarginB);
	}

	.collapse-download-btn {
		p {
			font-size: var(--p4) !important;
		}
	}

	.youtube-icon {
		line-height: 1;
	}

	.editor-wrapper p {
		font-size: var(--editorFontSize);
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--imgSize: 43.7%;
		--tabMarginT: var(--sp6x);
		--imgBorderRadius: var(--sp2x);
		--aboutContentMarginB: var(--sp4x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--tabMarginT: var(--sp5x);
		--tabMarginB: var(--sp4x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--tabMarginT: var(--sp4x);
		--tabMarginB: var(--sp6x);
		--imgMarginB: var(--sp3x);
		--imgBorderRadius: var(--sp1-5x);
		--aboutContentMarginB: var(--sp3x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--aboutContentMarginB: var(--sp2x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--tabMarginT: var(--sp6x);
		--tabMarginB: var(--sp4x);
		--aboutContentMarginB: var(--sp2-5x);
		--editorFontSize: var(--p1);

		.text-wrapper {
			font-size: var(--h6);
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--tabMarginT: var(--sp5x);
		--tabMarginB: var(--sp4x);
		--statusPadLR: var(--sp2x);
		--imgBorderRadius: var(--sp1x);
		--aboutContentMarginB: var(--sp3x);
		--editorFontSize: var(--p1);

		.tab-content-bottom {
			flex-direction: column;
			align-items: start;
			gap: var(--sp2x);
		}

		.reform-collapse-icon {
			display: none;
		}

		.collapse-top-section {
			flex-direction: column-reverse;
			align-items: start;
			gap: var(--sp1x);
		}

		.left-status-wrapper {
			justify-content: flex-start;
		}
	}
`;

export default ReformItemStyle;
