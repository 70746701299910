import gsap from 'gsap/all';
import { useEffect, useRef } from 'react';

//* Styles
import ContentStyle from './style';

//* Component\
import Text from '@/components/Text';
import Container from '@/components/Container';
import ScrollActive from '@/components/ScrollActive';

const Content = ({ data }) => {
	//! Refs
	const titleRef = useRef();
	const wrapperStatisticsRef = useRef();
	const lineRef = useRef();

	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	useEffect(() => {
		if (wrapperStatisticsRef.current) {
			[...wrapperStatisticsRef.current.children].forEach((item, index) => {
				gsap.set(item, { y: `80%`, opacity: 0, duration: 0 });
			});
			gsap.to(wrapperStatisticsRef.current, { opacity: 1, duration: 0, opacity: 1 });
			gsap.to([...wrapperStatisticsRef.current.children], { y: 0, opacity: 1, duration: 0.6, ease: 'power2.out', stagger: 0.12, delay: 0.4 });

			[...wrapperStatisticsRef.current.children].forEach((item, i) => {
				const itemsText = item.querySelector('.statistics-text-number');
				gsap.to(itemsText, { opacity: 1, duration: 0 });

				const fullText = itemsText.innerText;
				const match = fullText.match(/([+-])?(\d+)([kK]?)/);
				const sign = match[1] || '';
				const numberMatch = match[2];
				const suffix = match[3];
				if (numberMatch) {
					const startNumber = Number(numberMatch);

					gsap.from(itemsText, {
						duration: 0.6,
						textContent: 0,
						delay: 0.4 + 0.12 * i,
						ease: 'power1.out',
						onUpdate: function () {
							const currentValue = Math.ceil(this.targets()[0].textContent);
							const animatedNumber = numberWithCommas(currentValue);
							itemsText.innerHTML = `${sign}${animatedNumber}${suffix}`;
						},
						onComplete: function () {
							itemsText.innerHTML = `${sign}${numberWithCommas(startNumber)}${suffix}`;
						},
					});
				}
			});

			gsap.to(lineRef.current, { width: '100%', ease: 'power2.out', duration: 0.8, delay: 0.5 });
		}
	}, []);

	return (
		<ContentStyle>
			<Container>
				<div className='row'>
					<div className='col-4 col-t-12'>
						<div className='wrapper-title'>
							<ScrollActive
								animTitle
								allRefs={titleRef}
								overflow>
								<Text
									ref={titleRef}
									tag={'h1'}
									className={'uppercase h2 font-montserrat-arm-medium blue-1000 opacity-0'}>
									{data.title}
								</Text>
							</ScrollActive>
						</div>
					</div>

					<div className='col-2 col-t-12'></div>

					<div className='col-6 col-t-12'>
						<div
							ref={wrapperStatisticsRef}
							className='content-wrapper-statistics opacity-0'>
							{data?.info?.length > 0
								? data.info.map((item, index) => (
										<div
											className=''
											key={index}>
											<div className={`info-item-wrapper ${index === 1 ? 'second-item' : ''}`}>
												<Text className='h2 content-item-count font-montserrat-arm-medium statistics-text-number'>{item.additional_info.count}</Text>
												<Text className='p5 font-montserrat-arm-regular content-item-title'>{item.title}</Text>
											</div>
										</div>
								  ))
								: null}
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-12'>
						<div
							ref={lineRef}
							className='line-wrapper'></div>
					</div>
				</div>
			</Container>
		</ContentStyle>
	);
};

export default Content;
