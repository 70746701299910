//* Style
import FilterStyle from './style';

//* Components
import Text from '@/components/Text';
import Button from '@/components/Button';
import ScrollActive from '@/components/ScrollActive';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap/all';

const Filter = ({ onFilterChange, filterData, filterKey, searchParams, className, title, delay }) => {
	//! Ref
	const titleRef = useRef();
	const filterRefWrapper = useRef();

	//! State
	const [percent, setPercent] = useState(0);

	//! Animation
	useEffect(() => {
		if (filterRefWrapper.current) {
			if (filterRefWrapper.current.children.length && percent) {
				const heightItem = filterRefWrapper.current.children[0].clientHeight;

				gsap.fromTo(filterRefWrapper.current, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'power2.out', delay: delay + percent });
				gsap.fromTo(
					filterRefWrapper.current,
					{ y: heightItem },
					{
						y: 0,
						duration: 0.6,
						delay: delay + percent,
						ease: 'power2.out',
					}
				);
			}
		}
	}, [percent]);

	return (
		<FilterStyle className={`filter-container ${className || ''}`}>
			<ScrollActive
				animTitle
				overflow={true}
				allRefs={titleRef}
				delay={delay}
				percent
				percentDuration={30}
				percentFunction={setPercent}>
				<Text
					ref={titleRef}
					className={`p p1 filter-title font-montserrat-arm-medium blue-1000 opacity-0`}
					text={title}
				/>
			</ScrollActive>

			<div
				ref={filterRefWrapper}
				className='filter-wrapper opacity-0'>
				<Button
					text={'all'}
					className={`category filter p3 blue-900 ${searchParams.get(filterKey) == null ? 'active' : ''}`}
					onClick={() => onFilterChange(filterKey, null)}
				/>

				{filterData.map((item) => (
					<Button
						key={item.id}
						className={`category filter p3 blue-900 ${searchParams.get(filterKey) == item.id ? 'active' : ''}`}
						onClick={() => onFilterChange(filterKey, item.id)}>
						{item.name}
					</Button>
				))}
			</div>
		</FilterStyle>
	);
};

export default Filter;
