import styled from 'styled-components';

const NoResultsComponentStyle = styled.section`
	--imgSize: 12.9%;
	--marginT: var(--sp5x);
	--marginB: var(--sp2-5x);
	--contentMarginT: 0;

	margin-top: var(--contentMarginT);
	position: absolute;
	top: 14%;
	left: 50%;
	transform: translate(-50%, -50%);

	.image-wrapper .image-cont {
		--proportion: var(--imgSize);

		img {
			width: unset !important;
			left: 50% !important;
			transform: translate(-50%, 0) !important;
		}
	}

	.wrapper-text-title {
		margin-top: var(--marginT);
		margin-bottom: var(--marginB);

		h1,
		.tags {
			font-weight: 500;
		}
	}

	.tags {
		text-transform: capitalize;
	}

	.wrapper-text-title {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--imgSize: 14.7%;
		--marginT: var(--sp4x);
		--marginB: var(--sp2x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--marginT: var(--sp3-5x);
		--marginB: var(--sp1-5x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--imgSize: 15.7%;
		--marginT: var(--sp3x);
		--marginB: var(--sp1-5x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--marginT: var(--sp2-5x);
		--marginB: var(--sp1x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--imgSize: 7.7%;
		--marginT: var(--sp2x);
		--marginB: var(--sp1x);
		--contentMarginT: var(--sp5x);
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--imgSize: 11.1%;
		--marginT: var(--sp2x);
		--marginB: var(--sp1x);
		--contentMarginT: var(--sp4x);
	}
`;

export default NoResultsComponentStyle;
