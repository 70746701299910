import { useEffect, useRef, useState, useCallback } from 'react';
import { Draggable } from 'gsap/Draggable';

//* HOC's
import { withLanguageContext } from '@/context';

//* Style
import ReformItemStyle from './style';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import download from '@/lottieFiles/download.json';

//* Components
import Text from '@/components/Text';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Editor from '@/components/Editor';
import CustomLink from '@/components/CustomLink';
import CollapseItem from '@/components/Collapse/CollapseItem';
import Image from '@/components/Image';
import OpenCloseSvg from '@/components/Collapse/OpenCloseSvg';
import gsap from 'gsap/all';

const ReformItem = ({ slug, title, status, about_description, video, problem_description, change_description, mapped_description, translate }) => {
	//! Ref
	const tumblerRef = useRef();
	const tabsFlexRef = useRef();
	const youtubeRefLink = useRef();
	const draggableInstance = useRef();
	const draggableContainer = useRef();

	const availableTabs = [about_description && { key: 'about', label: translate('aboutTheReform') }, problem_description && { key: 'problem', label: translate('problemAndCurrentSituation') }, change_description && { key: 'change', label: translate('proposedChange') }, mapped_description && { key: 'mapped', label: translate('byWhomMapped') }, video && (video.youtube_link || video.file) && { key: 'video', label: translate('video') }].filter(Boolean);

	const [selectedTab, setSelectedTab] = useState(availableTabs[0]?.key || 'about');

	//! Download function
	const handleDownload = useCallback((id) => {
		const link = `${process.env.NEXT_PUBLIC_APP_API_SERVER}/download-file/${id}`;

		window.location.href = link;
	}, []);

	//! youtube  leave
	function leaveMouse() {
		if (youtubeRefLink.current.getAttribute('hovered') == 0) {
			youtubeRefLink.current.setAttribute('hovered', '1');
		}
	}
	const renderTabContent = () => {
		switch (selectedTab) {
			case 'about':
				return (
					<div>
						<div className='about-content'>
							<Editor data={about_description} />
						</div>

						<div className='wrapper-link'>
							<CustomLink
								url={`${config.routes.reforms.path}/${slug}`}
								className={'font-montserrat-arm-medium p4 checkout-link checkout-collapse arrow-animation-left-right'}>
								{translate('more_details')}

								<Icon
									className='arrow-icon'
									name='arrow'
								/>
							</CustomLink>
						</div>
					</div>
				);
			case 'video':
				return (
					<div>
						{video.thumbnail && (
							<Image
								className={'img-wrapper'}
								src={video.thumbnail?.src}
								alt={video.thumbnail?.alt}
							/>
						)}

						<div className='tab-content-bottom'>
							{video.youtube_link && (
								<div className='checkout-collapse '>
									<Icon
										className='youtube-icon h5'
										name='youtube'
									/>
									<div
										onMouseLeave={leaveMouse}
										hovered='0'
										className='link-animation-underline'
										ref={youtubeRefLink}>
										<CustomLink
											external
											url={video.youtube_link}
											className='font-montserrat-arm-medium p4 video-link'>
											{translate('to_see_on_youtube')}
										</CustomLink>
									</div>
								</div>
							)}

							{video.file && (
								<Button
									btnType={'lottie'}
									lottieFiles={download}
									lottieName={'download'}
									className={'font-montserrat-arm-medium collapse-download-btn'}
									onClick={() => handleDownload(video.file.id)}>
									{video.file.type.toUpperCase()}
								</Button>
							)}
						</div>
					</div>
				);
			case 'problem':
				return (
					<div>
						<Editor
							className='problem-content'
							data={problem_description}
						/>
					</div>
				);
			case 'change':
				return (
					<div>
						<Editor
							className='change-content'
							data={change_description}
						/>
					</div>
				);
			case 'mapped':
				return (
					<div>
						<Editor
							className='mapped-content'
							data={mapped_description}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	//! every click inner category
	function selectedHandleButton(tab, i) {
		setSelectedTab(tab);

		if (tumblerRef.current && tabsFlexRef.current) {
			const parentContainerSize = tabsFlexRef.current.getBoundingClientRect();
			const activeChildren = tabsFlexRef.current.children[i + 1];
			const childrenSize = activeChildren.getBoundingClientRect();
			const leftRelativeToParent = childrenSize.left - parentContainerSize.left;
			gsap.to(tumblerRef.current, { left: leftRelativeToParent, width: childrenSize.width, duration: 0.4, ease: 'power2.out' });
		}
	}

	//! first open size tumbler
	useEffect(() => {
		if (tabsFlexRef.current) {
			const activeChildren = tabsFlexRef.current.children[1];
			const childrenSize = activeChildren.getBoundingClientRect();

			gsap.to(tumblerRef.current, { left: 0, width: childrenSize.width, duration: 0 });
		}
	}, []);

	//! Draggable
	useEffect(() => {
		gsap.registerPlugin(Draggable);

		draggableInstance.current = Draggable.create(tabsFlexRef.current, {
			type: 'x',
			bounds: draggableContainer.current,
			inertia: true,
		});

		return () => {
			if (draggableInstance.current) {
				draggableInstance.current.forEach((instance) => instance.kill());
			}
		};
	}, []);

	return (
		<ReformItemStyle>
			<CollapseItem
				componentTitle={
					<div className='collapse-top-section row align-center'>
						<div className='col-9 col-m-12'>
							<Text className='font-montserrat-arm-medium blue-1000 text-wrapper'>{title}</Text>
						</div>
						<div className='col-2 col-m-12 flex justify-end left-status-wrapper'>
							<div className='wrapper-status'>
								<Text className='p5 font-montserrat-arm-medium blue-900'>{translate(status)}</Text>
							</div>
						</div>

						<div className='col-1 flex justify-end reform-collapse-icon'>
							<OpenCloseSvg />
						</div>
					</div>
				}
				componentDescription={
					<div className='collapse-bottom-section row'>
						<div className='col-11 col-t-12'>
							<div
								className='wrapper-outside-content-navigation'
								ref={draggableContainer}>
								<div
									ref={tabsFlexRef}
									className='tabs flex wrapper-navigation-inner-collapse'>
									<div className='empty-flex'>
										<div
											ref={tumblerRef}
											className='tumbler-item'
										/>
									</div>
									{availableTabs.map((tab, i) => (
										<Text
											key={tab.key}
											onClick={() => selectedHandleButton(tab.key, i)}
											className={`info-item-tabs-wrapper p5 blue-900 ${selectedTab === tab.key ? 'active' : ''}`}>
											{tab.label}
										</Text>
									))}
								</div>
							</div>

							<div className='tab-content'>{renderTabContent()}</div>
						</div>
						<div className='col-1 col-t-12' />
					</div>
				}></CollapseItem>
		</ReformItemStyle>
	);
};

export default withLanguageContext(ReformItem, ['translate']);
