import styled from 'styled-components';

const ReformsTemplateStyle = styled.section`
	--marginT: var(--sp15x);
	--marginB: var(--sp4x);
	--distanceButtonGap: var(--sp4x);
	--distanceFilterGap: var(--sp2x);
	--buttonPadTB: var(--sp1-5x);
	--buttonPadLR: var(--sp4x);
	--statusMarginB: var(--sp6x);
	--buttonRadius: var(--sp5x);
	--wrapperMarginB: var(--sp6x);

	margin-top: var(--marginT);

	.wrapper-container {
		position: relative;
		section {
			top: 40%;
		}
	}

	.status-filter {
		display: flex;
		flex-direction: column;
		gap: var(--distanceButtonGap);
		margin-bottom: var(--statusMarginB);
	}

	.filter-status-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: var(--distanceFilterGap);
	}

	.category {
		a,
		button {
			padding: var(--buttonPadTB) var(--buttonPadLR);
			border-radius: var(--buttonRadius);
			text-transform: capitalize;
			font-size: var(--p3);
			transition: all var(--trTime) ease-out;
			cursor: pointer;
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--marginT: var(--sp10x);
		--distanceButtonGap: var(--sp4x);
		--buttonPadLR: var(--sp2x);
		--statusMarginB: var(--sp4x);
		--buttonRadius: var(--sp3x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--marginT: var(--sp8x);
		--distanceButtonGap: var(--sp3-5x);
		--distanceFilterGap: var(--sp1-5x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--marginT: calc(var(--sp7x) + 4px);
		--distanceButtonGap: var(--sp3x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--marginT: var(--sp6x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--marginT: var(--sp4x);
		--distanceButtonGap: var(--sp2x);
		--distanceFilterGap: var(--sp1x);
		--statusMarginB: var(--sp3x);

		.wrapper-navigation {
			margin-bottom: var(--wrapperMarginB);
		}

		.wrapper-container {
			section {
				top: unset;
				left: unset;
				transform: unset;
				position: unset;
			}
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceButtonGap: var(--sp2x);
		--statusMarginB: var(--sp2x);
		--marginB: var(--sp5x);
		--wrapperMarginB: var(--sp4x);
	}
`;
export default ReformsTemplateStyle;
