'use client';

import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import gsap from 'gsap/all';

//* HOC's
import { withLanguageContext } from '@/context';

//* Styles
import ReformsTemplateStyle from './style';

//*	Helpers
import { createQueryString } from '@/helpers';

//* Components
import Text from '@/components/Text';
import Button from '@/components/Button';
import Filter from '@/components/Filter';
import Content from './components/Content';
import ReformItem from './components/Item';
import Container from '@/components/Container';
import ScrollActive from '@/components/ScrollActive';
import NoResultsComponent from '@/components/NoResultsComponent';
import CollapseContainer from '@/components/Collapse/CollapseContainer';

const ReformsTemplate = ({ pageData, itemsData, categoryData, translate }) => {
	//! Refs
	const titleRef = useRef();
	const wrapperButtonRef = useRef();
	const outsideCollapseContainerRef = useRef();
	const firstOpen = useRef(true);

	//! Next Navigation
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const [openType, setOpenType] = useState('button');
	const [statusFilter, setStatusFilter] = useState('all');

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 767) {
				setOpenType('');
			} else {
				setOpenType('button');
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	//! Status Filter
	const handleStatusChange = (status) => {
		setStatusFilter(status);
		const params = createQueryString({ status, searchParams });
		router.push(pathname + '?' + params);
	};

	//! Filter
	const onFilterChange = useCallback(
		(type, typeId, multiple = false) => {
			const params = createQueryString({ type, typeId, multiple, searchParams });

			router.push(pathname + '?' + params);
		},
		[searchParams]
	);

	//! Reform Item
	const dataPosts = useMemo(() => {
		const categoryId = searchParams.get('categoryId');
		const filteredItems = itemsData.filter((item) => (statusFilter === 'all' || item.status === statusFilter) && (!categoryId || item.category?.id === parseInt(categoryId)));

		return filteredItems.map((item) => {
			return (
				<div key={item.id}>
					<ReformItem {...item} />
				</div>
			);
		});
	}, [itemsData, statusFilter, searchParams]);

	//! Animated
	useEffect(() => {
		if (wrapperButtonRef.current) {
			gsap.to(wrapperButtonRef.current, { opacity: 1, duration: 0.3, ease: 'power2.out', delay: 0.9 });
			gsap.to(wrapperButtonRef.current, { y: 0, duration: 0.6, ease: 'power2.out', delay: 0.9 });
		}
	}, []);

	useEffect(() => {
		if (outsideCollapseContainerRef.current) {
			const delayTime = firstOpen.current ? 1.2 : 0;
			gsap.set([...outsideCollapseContainerRef.current.children[0].children], { y: '200%', delay: delayTime, duration: 0 });
			gsap.fromTo(outsideCollapseContainerRef.current, { opacity: 0 }, { opacity: 1, delay: delayTime });
			gsap.to([...outsideCollapseContainerRef.current.children[0].children], { y: 0, stagger: 0.1, delay: delayTime });
			firstOpen.current = false;
		}
	}, [statusFilter, searchParams]);

	const availableStatuses = useMemo(() => {
		const statuses = new Set(itemsData.map((item) => item.status));
		return Array.from(statuses);
	}, [itemsData]);

	const tags = useMemo(() => {
		const categoryId = searchParams.get('categoryId');
		const categoryTag = categoryData.data.find((cat) => cat.id === parseInt(categoryId))?.name || '';

		const statusTag = statusFilter !== 'all' ? translate(statusFilter) : '';
		return [statusTag, categoryTag].filter(Boolean);
	}, [categoryData.data, statusFilter, searchParams, translate]);

	return (
		<ReformsTemplateStyle>
			<Content data={pageData} />

			<Container isSection>
				<div className='row'>
					<div className='col-4 col-t-12 wrapper-navigation'>
						<div className='filter-categories-wrapper'>
							<div className='status-filter'>
								<ScrollActive
									animTitle
									overflow
									allRefs={titleRef}
									delay={0.8}>
									<Text
										ref={titleRef}
										className='p1 blue-1000 font-montserrat-arm-medium opacity-0'>
										{translate('filterByCat')}
									</Text>
								</ScrollActive>

								<div>
									<div
										ref={wrapperButtonRef}
										className='filter-status-wrapper translate-y-100 opacity-0'>
										<Button
											text={'all'}
											onClick={() => handleStatusChange('all')}
											className={`category filter p3 blue-900 ${statusFilter === 'all' ? 'active' : ''}`}
										/>

										{availableStatuses.includes('adopted') && (
											<Button
												text={'adopted'}
												onClick={() => handleStatusChange('adopted')}
												className={`category filter p3 blue-900 ${statusFilter === 'adopted' ? 'active' : ''}`}
											/>
										)}

										{availableStatuses.includes('ongoing') && (
											<Button
												text={'ongoing'}
												onClick={() => handleStatusChange('ongoing')}
												className={`category filter p3 blue-900 ${statusFilter === 'ongoing' ? 'active' : ''}`}
											/>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className='filter-reform-wrapper'>
							<Filter
								title={'filterByReformsCat'}
								filterData={categoryData.data}
								filterKey={'categoryId'}
								onFilterChange={onFilterChange}
								searchParams={searchParams}
								className={'filter-wrapper'}
								delay={0.9}
							/>
						</div>
					</div>

					{dataPosts.length > 0 ? (
						<div className='col-8 col-t-12'>
							<div
								ref={outsideCollapseContainerRef}
								className='wrapper-container opacity-0'>
								<CollapseContainer
									key={`${statusFilter}-${searchParams.get('categoryId')}`}
									openType={openType}>
									{dataPosts}
								</CollapseContainer>
							</div>
						</div>
					) : (
						<div className='col-8 col-t-12 wrapper-container'>
							<NoResultsComponent tags={tags} />
						</div>
					)}
				</div>
			</Container>
		</ReformsTemplateStyle>
	);
};

export default withLanguageContext(ReformsTemplate, ['translate']);
