import styled from 'styled-components';

const FilterStyle = styled.div`
	--distanceButtonGap: var(--sp4x);
	--distanceFilterGap: var(--sp2x);
	--buttonPadTB: var(--sp1-5x);
	--buttonPadLR: var(--sp4x);
	--buttonRadius: var(--sp5x);

	display: flex;
	flex-direction: column;
	gap: var(--distanceButtonGap);

	.filter-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: var(--distanceFilterGap);
	}

	.category {
		a,
		button {
			padding: var(--buttonPadTB) var(--buttonPadLR);
			border-radius: var(--buttonRadius);
			text-transform: capitalize;
			font-size: var(--p3);
			transition: all var(--trTime) ease-out;
			cursor: pointer;
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--distanceButtonGap: var(--sp4x);
		--buttonPadLR: var(--sp2x);
		--buttonRadius: var(--sp3x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--distanceButtonGap: var(--sp3-5x);
		--distanceFilterGap: var(--sp1-5x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--distanceButtonGap: var(--sp3x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--distanceButtonGap: var(--sp2x);
		--distanceFilterGap: var(--sp1x);
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--distanceButtonGap: var(--sp2x);
	}
`;

export default FilterStyle;
