//* HOC's
import { withLanguageContext } from '@/context';

//* Style
import NoResultsComponentStyle from './style';

//* Components
import Text from '@/components/Text';
import Image from '@/components/Image';

const NoResultsComponent = ({ tags, translate }) => {
	return (
		<NoResultsComponentStyle>
			<div className='image-wrapper'>
				<Image
					src={'/images/empty.png'}
					alt={'empty'}
				/>
			</div>

			<div className='wrapper-text-title text-center'>
				<Text
					tag={'h1'}
					className={'h5 font-montserrat-medium blue900'}>
					{translate('noResult')}
				</Text>

				{tags.length > 0 && <Text className={'h5 font-montserrat-medium blue900 tags'}> {tags.map((tag) => `“${translate(tag)}”`).join(', ')}</Text>}
			</div>

			<div className='wrapper-text-description text-center'>
				<Text className={'p3 font-montserrat-medium blue900'}>{translate('noResultDescription')}</Text>
			</div>
		</NoResultsComponentStyle>
	);
};

export default withLanguageContext(NoResultsComponent, ['translate']);
