import styled from 'styled-components';

const ContentStyle = styled.section`
	--titleMarginB: var(--sp3x);
	--itemPadTB: var(--sp4x);
	--itemPadLR: var(--sp3x);
	--lineMarginT: var(--sp9x);
	--lineMarginB: var(--sp8x);

	.info-item-wrapper {
		border-radius: var(--sp2x);
		background: var(--white);
		padding: var(--itemPadTB) var(--itemPadLR);

		.content-item-count {
			color: var(--lightBlue);
			margin-bottom: var(--sp1x);
		}
	}

	.line-wrapper {
		width: 0%;
		height: 2px;
		border-radius: 2px;
		background: var(--white);
		margin: var(--lineMarginT) 0 var(--lineMarginB);
	}

	.content-wrapper-statistics {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: var(--gutter);
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--itemPadTB: var(--sp3x);
		--lineMarginT: var(--sp7x);
		--lineMarginB: var(--sp7x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--itemPadTB: var(--sp2x);
		--itemPadLR: var(--sp2x);
		--lineMarginT: var(--sp5x);
		--lineMarginB: var(--sp5x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--lineMarginT: var(--sp4x);
		--lineMarginB: var(--sp4x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--lineMarginT: var(--sp3x);
		--lineMarginB: var(--sp6x);

		.wrapper-title {
			margin-bottom: var(--titleMarginB);
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--titleMarginB: var(--sp4x);
		--lineMarginT: var(--sp4x);
		--lineMarginB: var(--sp4x);

		.content-wrapper-statistics {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(3, auto);
			gap: var(--sp1x);
		}
	}
`;

export default ContentStyle;
